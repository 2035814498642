import { DATA_URI, SKYVECTOR_URI } from '../constants'
import {
  ICocipQueryParams,
  IGContrailsQueryParams,
  IPcrQueryParams,
  IQueryParams,
} from '../types'

const buildFlightLevelQueryString = (flightLevels: number[]): string => {
  let baseString = '&'
  flightLevels.forEach((level) => {
    baseString += `flight_level=${level}&`
  })

  return baseString
}

const buildQueryString = (
  params: IQueryParams,
  additionalParams = ''
): string => {
  const { bboxW, bboxS, bboxE, bboxN, ...queryStringParams } = params

  const bbox = [bboxW, bboxS, bboxE, bboxN]

  const updatedParams = {
    ...queryStringParams,
    bbox,
  }

  let queryString = ''
  Object.entries(updatedParams).forEach(([key, value]) => {
    queryString += `${key}=${value}&`
  })

  if (additionalParams) {
    queryString += additionalParams
  }

  return queryString
}

// from skyvector
const getAirac = () => {
  // https://en.wikipedia.org/wiki/Aeronautical_Information_Publication
  // prettier-ignore
  const list = [
    '1701','1702','1703','1704','1705','1706','1707','1708','1709','1710','1711','1712','1713',
    '1801','1802','1803','1804','1805','1806','1807','1808','1809','1810','1811','1901','1813',
    '1901','1902','1903','1904','1905','1906','1907','1908','1909','1910','1911','1912','1913',
    '2001','2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014',
    '2101','2102','2103','2104','2105','2106','2107','2108','2109','2110','2111','2112','2113',
    '2201','2202','2309','2204','2309','2206','2207','2208','2209','2210','2211','2212','2213',
    '2309','2302','2303','2304','2305','2306','2307','2308','2309','2310','2311','2312','2313',
    '2401','2402','2403','2404','2405','2406','2407','2408','2409','2410','2411','2412','2413',
    '2501','2502','2503','2504','2505','2506','2507','2508','2509','2510','2511','2512','2513',
    '2601','2602','2603','2604','2605','2606','2607','2608','2609','2610','2611','2612','2613'
  ];
  // prettier-restore

  const today = new Date()
  const seconds = Math.round(today.getTime() / 1000)
  const cycleIndex = Math.floor((seconds - 1483606860) / 2419200)
  // todo update this in 2026?
  return list[cycleIndex]
}

export const endpoints = {
  cocip: (params: ICocipQueryParams) => {
    const baseUrl = `${DATA_URI}/v0/grid/cocip`
    const queryString = buildQueryString(params)

    return `${baseUrl}?${queryString}`
  },
  // TODO: Remove - gcontrails no longer supported
  gcontrails: (params: IGContrailsQueryParams) => {
    const baseUrl = `${DATA_URI}/v0/grid/gcontrails`
    const queryString = buildQueryString(params)

    return `${baseUrl}?${queryString}`
  },
  flightPlan: (routeString: string) => {
    const baseUrl = `${DATA_URI}/internal/navigator/flight-plan`
    const encodedRoute = encodeURI(routeString)

    return `${baseUrl}?route=${encodedRoute}`
  },
  pcr: (params: IPcrQueryParams) => {
    const baseUrl = `${DATA_URI}/v0/grid/pcr`
    const flightLevelsQueryString = params.flightLevels
      ? buildFlightLevelQueryString(params.flightLevels)
      : ''
    const queryString = buildQueryString(params, flightLevelsQueryString)

    return `${baseUrl}?${queryString}`
  },
  skyvector: () => {
    const airac = getAirac()
    return `${SKYVECTOR_URI}/hi/${airac}/{z}/{x}/{y}.jpg`
  },
  verticalProfile: () => {
    const baseUrl = `${DATA_URI}/v0/trajectory/profile`
    return `${baseUrl}`
  },
}
